<template>
	<div
		class="coinbase-button"
		:class="{ 'coinbase-button-mobile-height': isMobile }"
		v-ripple
		@click="checkout"
	>
		<v-icon class="mr-2 mb-1" color="white" dense> mdi-bitcoin </v-icon>
		<span class="button-text">Pay with <b>Coinbase</b></span>
	</div>
</template>

<script>
export default {
	name: 'ShopCheckoutCoinbaseButton',
	data: () => ({
		disabled: false,
	}),
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width < 1000;
		},
	},
	methods: {
		async checkout() {
			if (this.disabled) {
				const opt = {
					text: 'Sowwy! Crypto payments are currently unavailable. Please check again later!',
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showError();
			}
			if (this.$store.getters.totalCost < 3) {
				const opt = {
					text: 'Minimum of $3.00 is required.',
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showWarn();
			}
			try {
				const result = await this.showLoading(
					this.$store.dispatch(
						'createCoinbaseTransaction',
						this.$store.getters.giftTo?.id
					)
				);
				window.location.href = result.redirect;
			} catch (err) {
				this.showError();
			}
		},
		showError() {
			const opt = {
				text: "Oopsies. We weren't able to process the payment! Please try again later!",
				imgUrl: 'owo-cry.png',
			};
			return this.$modal(opt).showError();
		},
		showLoading(promise) {
			const opt = {
				text: 'We are current processing the payment! Please be patient...',
				imgUrl: 'owo-peek.png',
				persistent: true,
				loading: true,
				wait: promise,
			};
			return this.$modal(opt).showInfo();
		},
	},
};
</script>

<style scoped>
.coinbase-button {
	height: 35px;
	transition: all 0.2s ease-in-out 0s;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
	min-height: 25px;
	width: calc(100% - 32px);
	background: #1554f0;
	margin-left: 16px;
	margin-right: 16px;
	border-radius: 4px;
	text-align: center;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 10px;
	font-weight: 500;
	padding-top: 5px;
	cursor: pointer;
}

.button-text {
	margin-top: -3px;
	font-size: 13px;
}

@media screen and (max-width: 749px) {
	.coinbase-button-mobile-height {
		min-height: 55px;
		font-size: 20px;
	}
}
@media screen and (max-width: 571px) {
	.coinbase-button-mobile-height {
		min-height: 45px;
		font-size: 16px;
	}
}
@media screen and (max-width: 371px) {
	.coinbase-button-mobile-height {
		min-height: 35px;
		font-size: 13px;
	}
}
@media screen and (max-width: 271px) {
	.coinbase-button-mobile-height {
		min-height: 25px;
		font-size: 10px;
	}
}
</style>
