<template>
	<div :class="isMobile ? 'store-mobile' : 'store'">
		<v-card
			:class="isMobile ? 'category-mobile' : 'category'"
			color="background_dark2"
			elevation="2"
		>
			<ShopCategories @select="selectCategory" />
		</v-card>
		<v-card
			:class="isMobile ? 'items-mobile' : 'items'"
			color="background_dark2"
			elevation="0"
		>
			<ShopItems :selectedCategory="selectedCategory" @itemAdded="itemAdded" />
		</v-card>
		<v-expand-x-transition>
			<div
				v-if="cartHasItem"
				:class="isMobile ? 'checkout-mobile' : 'checkout'"
			>
				<v-card color="background_dark2" elevation="2">
					<ShopCheckout ref="checkout" />
				</v-card>
				<v-card class="mt-2" color="background_dark2" elevation="2">
					<RefundPolicy />
				</v-card>
			</div>
		</v-expand-x-transition>
		<v-dialog v-model="fetchError" width="min-content" height="min-content">
			<v-card
				color="background"
				width="300"
				class="error-card pt-4"
				:style="{ 'border-color': 'var(--v-error-base) !important' }"
			>
				<v-img
					contain
					width="120"
					src="@/assets/owo-cry.png"
					class="mb-3"
				></v-img>
				<v-card-text class="modal-text">
					Oops! It seems like we failed to fetch the shop catalog! Please try
					again later!
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="showWarning" width="min-content" height="min-content">
			<v-card
				color="background"
				width="300"
				class="warn-card pt-4"
				:style="{ 'border-color': 'var(--v-error-base) !important' }"
			>
				<v-img
					contain
					width="120"
					src="@/assets/owo-cry.png"
					class="mb-3"
				></v-img>
				<v-card-text v-if="shopDisabled" class="modal-text">
					Sorry! We are currently having issues with payments. Purchases will be
					unavailable until this is resolved. Sorry for any inconvenience!
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import ShopCategories from '@/views/store/components/ShopCategories.vue';
import ShopItems from '@/views/store/components/ShopItems.vue';
import ShopCheckout from '@/views/store/components/ShopCheckout.vue';
import RefundPolicy from '@/views/store/components/RefundPolicy.vue';

export default {
	name: 'Storepage',
	components: {
		ShopCategories,
		ShopItems,
		ShopCheckout,
		RefundPolicy,
	},
	data: () => ({
		selectedCategory: '',
		fetchError: false,
		showWarning: false,
	}),
	computed: {
		cartHasItem() {
			return !!Object.keys(this.$store.getters.cart).length;
		},
		isMobile() {
			return this.$vuetify.breakpoint.width < 750;
		},
		shopDisabled() {
			return this.$store.getters.shopDisabled;
		},
	},
	methods: {
		selectCategory(category) {
			this.selectedCategory = category;
		},
		itemAdded(item) {
			if (this.$refs.checkout) {
				this.$refs.checkout.itemAdded(item);
			}
		},
		showError() {
			const opt = {
				text: "Oopsies. We weren't able to process the payment! Please try again later!",
				imgUrl: 'owo-cry.png',
			};
			return this.$modal(opt).showError();
		},
		showLoading(promise) {
			const opt = {
				text: 'We are current processing the payment! Please be patient...',
				imgUrl: 'owo-peek.png',
				persistent: true,
				loading: true,
				wait: promise,
			};
			return this.$modal(opt).showInfo();
		},
		showSuccess(text) {
			const opt = {
				text:
					text ||
					'Thank you for the payment! The items should already be in your Discord account. Enjoy!',
				imgUrl: 'owo-peek.png',
				confetti: true,
			};
			return this.$modal(opt).showInfo();
		},
		async handleCoinbase(coinbase) {
			let status;
			try {
				const result = await this.showLoading(
					this.$store.dispatch('captureCoinbaseTransaction', coinbase)
				);
				status = result.status;
			} catch (err) {
				return this.showError();
			}
			if (status === 200) {
				await this.showSuccess();
			} else {
				await this.showSuccess(
					'Thank you for the payment! We are still processing the payment, but it should be in your inventory soon!'
				);
			}
			this.$store.dispatch('clearCart');
			this.$store.dispatch('clearGiftTo');
		},
		async handleStripe(stripe) {
			try {
				await this.showLoading(
					this.$store.dispatch('captureStripeTransaction', stripe)
				);
			} catch (err) {
				return this.showError();
			}
			await this.showSuccess();
			this.$store.dispatch('clearCart');
			this.$store.dispatch('clearGiftTo');
		},
		async handleSquare(square) {
			try {
				await this.showLoading(
					this.$store.dispatch('captureSquareTransaction', square)
				);
			} catch (err) {
				return this.showError();
			}
			await this.showSuccess();
			this.$store.dispatch('clearCart');
			this.$store.dispatch('clearGiftTo');
		},
		async handleClover(clover) {
			try {
				await this.showLoading(
					this.$store.dispatch('captureCloverTransaction', clover)
				);
			} catch (err) {
				return this.showError();
			}
			await this.showSuccess();
			this.$store.dispatch('clearCart');
			this.$store.dispatch('clearGiftTo');
		},
	},
	async mounted() {
		const { canceled, coinbase, stripe, square, clover } = this.$route.query;
		this.$router.replace({ query: null });

		try {
			const catalog = await this.$store.dispatch('fetchCatalog');
			this.selectedCategory = Object.keys(catalog)[0];
		} catch (err) {
			this.fetchError = true;
		}

		if (canceled) {
			this.showError();
		} else if (stripe) {
			this.handleStripe(stripe);
		} else if (coinbase) {
			this.handleCoinbase(coinbase);
		} else if (square) {
			this.handleSquare(square);
		} else if (clover) {
			this.handleClover(clover);
		}
	},
};
</script>

<style scoped>
.store {
	min-width: 100%;
	min-height: 100%;
	display: flex;
	justify-content: center;
	padding: 20px;
}

.store-mobile {
	padding: 20px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.category {
	width: min-content;
	min-width: 200px;
	height: min-content;
}

.category-mobile {
	width: 100%;
	height: min-content;
	margin-bottom: 20px;
}

.items {
	width: 50%;
	min-width: 300px;
	max-width: 700px;
	height: min-content;
	margin-right: 10px;
	margin-left: 10px;
}

.items-mobile {
	width: 100%;
	height: min-content;
	margin-bottom: 20px;
}

.checkout {
	height: min-content;
}

.checkout-mobile {
	width: 100%;
	height: min-content;
}

.error-card {
	display: flex;
	align-items: center;
	flex-direction: column;
	border: 3px solid var(--v-error-base);
}

.warn-card {
	display: flex;
	align-items: center;
	flex-direction: column;
	border: 3px solid var(--v-secondary-base);
}

.modal-text {
	color: white !important;
	text-align: center;
	font-size: 1rem;
}
</style>
