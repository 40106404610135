<template>
	<div class="refund">
		<v-card-title class="subtitle-2 mb-2 title-text overflow">
			<v-icon small class="mr-2">mdi-information-outline</v-icon>
			Refund Policy
		</v-card-title>
		<v-card-subtitle class="subtitle-2 refund-text">
			Please be aware that we do not offer refunds. Digital goods shall not be
			traded with anything outside of the bot. Failure to follow the rules will
			result in a ban. You will lose your items and account.
		</v-card-subtitle>
	</div>
</template>

<script>
export default {
	name: 'RefundPolicy',
};
</script>

<style scoped>
.refund {
	overflow: hidden;
	width: min-content;
	min-width: 100%;
}

.overflow {
	white-space: nowrap;
	display: inline-block;
}

.refund-text {
	text-align: center;
	width: 100%;
}
</style>
