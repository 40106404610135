<template>
	<div>
		<div
			v-if="!savedFriend"
			class="gift-button"
			:class="{ 'gift-button-mobile-height': isMobile }"
			v-ripple
			@click="showGiftModal"
		>
			<v-icon class="mr-2 mb-1" color="white" dense> mdi-gift </v-icon>
			<span class="button-text">Gifting</span>
			<v-dialog
				v-model="showFriendModal"
				width="min-content"
				height="min-content"
			>
				<v-card
					color="background"
					width="350"
					class="gift-card"
					:style="{ 'border-color': 'var(--v-primary-base) !important' }"
				>
					<v-card-title class="pb-0">Gifting a friend?</v-card-title>
					<v-card-text class="info-text">
						<div>Copy and paste their Discord user ID here!</div>
						<a :href="helpLink" target="_blank" class="how-text"
							>How do I get a user ID?</a
						>
						<v-text-field
							class="pt-7"
							clearable
							dense
							:loading="fetching"
							label="Discord ID"
							@input="typing"
							:rules="idRules"
							:error-messages="fieldErrors"
						>
						</v-text-field>
					</v-card-text>
					<v-card-actions class="pb-6 pt-0">
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							:disabled="!friend"
							@click="proceedGifting"
							>{{ buttonText }}</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog
				v-model="showConfirmModal"
				width="min-content"
				height="min-content"
			>
				<v-card
					color="background"
					width="350"
					class="gift-card"
					:style="{ 'border-color': 'var(--v-primary-base) !important' }"
				>
					<v-card-title class="title-text pb-0"
						>Are you sure you want to gift to
						{{ friendUsername }}?</v-card-title
					>
					<v-card-text class="info-text">
						<div>
							This is not refundable. Please double check your friend's ID!
						</div>
					</v-card-text>
					<v-card-actions class="pb-6 pt-0">
						<v-spacer></v-spacer>
						<v-btn color="primary" @click="finalizeGift">Yes I am sure!</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<div v-else>
			<div
				class="cancel-gift-button"
				:class="{ 'gift-button-mobile-height': isMobile }"
				v-ripple
				@click="cancelGift"
			>
				Cancel Gifting to {{ savedFriend.username }}#{{
					savedFriend.discriminator
				}}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ShopCheckoutGiftButton',
	data: () => ({
		showFriendModal: false,
		showConfirmModal: false,
		fetching: false,
		helpLink:
			'https://support.discord.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-',
		friend: null,
		friendId: null,
		invalidId: false,
		blurTimeout: null,
		confirmDisabled: false,
		idRules: [
			(v) => !!v || 'ID Required',
			(v) => {
				const pattern = /^\d{15,20}$/;
				return pattern.test(v) || 'Invalid Discord ID';
			},
		],
	}),
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width < 1000;
		},
		fieldErrors() {
			if (this.invalidId) {
				return ['Could not find user with this ID'];
			}
			return [];
		},
		buttonText() {
			if (this.friendUsername) {
				return `Gift to ${this.friendUsername}`;
			} else {
				return 'Gift to...';
			}
		},
		friendUsername() {
			if (this.friend) {
				return `${this.friend.username}#${this.friend.discriminator}`;
			} else {
				return null;
			}
		},
		savedFriend() {
			return this.$store.getters.giftTo;
		},
	},
	methods: {
		validId(id) {
			return /^\d{15,20}$/.test(id);
		},
		typing(id) {
			this.invalidId = false;
			this.friend = null;
			this.fetching = false;
			this.friendId = null;
			clearTimeout(this.blurTimeout);
			if (!this.validId(id)) {
				return;
			}
			this.fetching = true;
			this.blurTimeout = setTimeout(() => {
				this.fetchUsername(id);
			}, 2000);
		},
		showGiftModal() {
			this.showFriendModal = true;
			this.showConfirmModal = false;
		},
		async fetchUsername(id) {
			this.invalidId = false;
			this.friend = null;
			this.friendId = null;
			if (!this.validId(id)) {
				return;
			}
			this.fetching = true;
			try {
				const user = await this.$store.dispatch('getUserById', id);
				this.friend = user;
				this.friendId = id;
			} catch (err) {
				this.invalidId = true;
			} finally {
				this.fetching = false;
			}
		},
		proceedGifting() {
			clearTimeout(this.blurTimeout);
			this.fetching = false;
			this.showFriendModal = false;
			this.showConfirmModal = true;
			this.confirmDisabled = true;
			setTimeout(() => {
				this.confirmDisabled = false;
			}, 2000);
		},
		finalizeGift() {
			this.$store.dispatch('setGiftTo', this.friend);
			this.showConfirmModal = false;
		},
		cancelGift() {
			this.friend = null;
			this.friendId = null;
			this.$store.dispatch('clearGiftTo');
		},
	},
};
</script>

<style scoped>
.gift-button {
	margin: 0px !important;
	height: 35px;
	transition: all 0.2s ease-in-out 0s;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
	min-height: 25px;
	width: calc(100%);
	background: var(--v-primary-base);
	margin-left: 16px;
	margin-right: 16px;
	border-radius: 4px;
	text-align: center;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 15px;
	font-weight: 500;
	padding-top: 5px;
	cursor: pointer;
}

.cancel-gift-button {
	margin: 0px !important;
	height: 35px;
	transition: all 0.2s ease-in-out 0s;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
	min-height: 25px;
	width: calc(100%);
	background: rgba(0, 0, 0, 0);
	border: thin solid var(--v-error-base);
	margin-left: 16px;
	margin-right: 16px;
	border-radius: 4px;
	text-align: center;
	color: var(--v-error-base);
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 12px;
	font-weight: 500;
	padding-top: 5px;
	cursor: pointer;
}

.button-text {
	font-size: 13px;
	margin-top: -3px;
}

@media screen and (max-width: 749px) {
	.gift-button-mobile-height {
		min-height: 55px;
		font-size: 20px;
	}
}
@media screen and (max-width: 571px) {
	.gift-button-mobile-height {
		min-height: 45px;
		font-size: 16px;
	}
}
@media screen and (max-width: 371px) {
	.gift-button-mobile-height {
		min-height: 35px;
		font-size: 13px;
	}
}
@media screen and (max-width: 271px) {
	.gift-button-mobile-height {
		min-height: 25px;
		font-size: 10px;
	}
}

.gift-card {
	display: flex;
	align-items: center;
	flex-direction: column;
	border: 3px solid var(--v-primary-base);
}

.info-text {
	text-align: center;
}

.how-text {
	font-size: 0.8rem;
}

.title-text {
	text-align: center;
}
</style>
