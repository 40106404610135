<template>
	<v-list dense color="background_dark2">
		<v-subheader>Categories</v-subheader>
		<v-list-item-group
			v-model="selectedItem"
			@change="selectItem"
			color="primary"
			mandatory
		>
			<v-list-item
				v-for="(item, i) in items"
				:key="i"
				@click="selectItem(i)"
				:href="item.link"
			>
				<v-list-item-icon>
					<v-img
						contain
						:alt="item.id"
						:src="require(`@/assets/` + item.icon)"
						width="16"
					/>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title v-text="item.text"></v-list-item-title>
				</v-list-item-content>
				<v-list-item-action v-if="item.link">
					<v-icon small>mdi-open-in-new</v-icon>
				</v-list-item-action>
			</v-list-item>
		</v-list-item-group>
	</v-list>
</template>

<script>
export default {
	name: 'ShopCategories',
	data: () => ({
		selectedItem: null,
	}),
	computed: {
		items() {
			const storeCatalog = this.$store.getters.catalog;
			if (!Object.keys(storeCatalog).length) return {};
			const catalog = { ...storeCatalog };
			catalog.patreon = {
				icon: 'patreon-logo-red.png',
				id: 'patreon',
				text: 'Patreon',
				link: 'https://www.patreon.com/OwOBot',
			};
			return catalog;
		},
	},
	methods: {
		selectItem(index) {
			if (this.items[index]?.items) {
				this.$emit('select', this.items[index].id);
			}
		},
	},
};
</script>

<style></style>
