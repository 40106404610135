<template>
	<v-hover v-slot="{ hover }">
		<v-card
			class="mx-2 my-2 noselect"
			color="background"
			elevation="3"
			width="200"
			v-ripple="{ class: `secondary--text` }"
			@click="addToCart"
			:disabled="item.disabled"
		>
			<div class="top-half py-4">
				<v-img
					contain
					:alt="item.id"
					:src="require(`@/assets/` + item.icon)"
					height="64"
				/>
				<v-expand-transition>
					<div class="overlay" v-if="hover">
						{{ item.desc }}
					</div>
				</v-expand-transition>
			</div>
			<v-card-title class="title-text">
				{{ item.text }}
			</v-card-title>
			<v-card-subtitle class="subtitle-text">
				{{ item['price-text'] }}
			</v-card-subtitle>
		</v-card>
	</v-hover>
</template>

<script>
export default {
	name: 'ShopItem',
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	methods: {
		addToCart() {
			this.$store.dispatch('addToCart', { item: this.item, count: 1 });
			this.$emit('itemAdded', this.item);
		},
	},
};
</script>

<style scoped>
.title-text {
	font-size: 0.9rem !important;
}

.subtitle-text {
	font-size: 0.8rem !important;
}

.top-half {
	background: var(--v-background-lighten1);
	position: relative;
}

.overlay {
	position: absolute;
	background: var(--v-primary-base);
	width: 100%;
	height: 100%;
	bottom: 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
