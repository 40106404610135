<template>
	<div class="card-holder">
		<ShopItem
			class="item"
			v-for="item in items"
			:key="item.id"
			:item="item"
			@itemAdded="$emit('itemAdded', $event)"
		/>
	</div>
</template>

<script>
import ShopItem from '@/views/store/components/ShopItem.vue';

export default {
	name: 'ShopItems',
	components: {
		ShopItem,
	},
	props: {
		selectedCategory: {
			type: String,
			required: true,
		},
	},
	computed: {
		items() {
			return this.categories[this.selectedCategory]?.items || [];
		},
		categories() {
			return this.$store.getters.catalog;
		},
	},
};
</script>

<style>
.card-holder {
	width: 100%;
	height: 100%;
	padding: 10px 5px;
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	align-content: flex-start;
	flex-wrap: wrap;
}
</style>
